import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    pricing: Object,
  }
  static targets = ['plan', 'enterprise']

  connect() {
    // Jan 2023 - disabled price calculations on this page.
    // it's better if they go through to the stripe dashboard.
    this.planChange()
  }



  // TODO: remove me
  calculatePrice() {
    const quantity = parseInt(this.quantityTarget.value) || 1
    const plan = this.planTarget.value
    // array of two prices, normal, volume
    const rawPrice = this.pricingValue[plan]
    const price = quantity > 4 ? Math.round(rawPrice * 0.8) : rawPrice

    const quotedPrice = price * quantity
    this.unitPriceTarget.textContent = price
    this.priceTarget.textContent = quotedPrice
  }

  planChange(event) {
    // this.calculatePrice()
    if (this.planTarget.value === 'enterprise') {
      this.enterpriseTarget.style.display = null;
    } else {
      this.enterpriseTarget.style.display = 'none';
    }
  }

}
